import queryString from 'query-string';
import { type FormEvent, type KeyboardEvent, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import type { IRootState } from 'reducers';
import type { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { hideError, login, requestResetPassword } from 'actions/auth';
import { logoFull } from '../../assets/images/eezylogo';
import { EezyButton, InlineButtonLink } from 'components/Buttons';
import ErrorBox from '../../components/ErrorBox';
import { FormInput, FormSection } from 'components/form';
import { Icon } from 'components/Icon';
import LanguageOptions from 'components/LanguageOptions';
import LoadingSpinner from 'components/Loading';
import { PLight, UniqueTitleCenter } from 'components/textElements';
import type { IAuth } from 'reducers/authReducer';
import {
    BORDER_RADIUS,
    COLOR_BLACKWATER,
    COLOR_DARK_GRAY,
    COLOR_GREY_FOG,
    COLOR_LILA,
    COLOR_WHITE_WALKER,
    FOOTER_HEIGHT,
    FOOTER_HEIGHT_MOBILE,
    SCREEN_M,
    SCREEN_XS,
    TOPBAR_HEIGHT,
} from 'styles/variables';
import { useTranslation } from 'react-i18next';
import { saveResetEmail } from 'utils/user/userUtils';
import Bar, { BarContent } from '../nav/Bar';
import PurpleBackground from '../nav/PurpleBackground';
import PasswordLinkSent from './PasswordLinkSent';
import Footer from '../../components/Footer';
import AuthCode from 'react-auth-code-input';
import { useChangeUserLanguageMutation } from 'queries/useUserQuery';
import onLogoutWithholdingTax from 'queries/fetchGetWithholdingTax/callback.onLogout';

const VERIFICATION_CODE_LENGTH = 4;

interface IFormData {
    name: string;
    password: string;
    tfToken?: string;
}

const ContentWrapper = styled.div`
    display: block;
    margin: 0 auto;
    padding-top: 40px;
    text-align: center;
    min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT_MOBILE}px);
    @media (min-width: ${SCREEN_M}px) {
        min-height: calc(100vh - ${TOPBAR_HEIGHT + FOOTER_HEIGHT}px);
    }
`;

const LoginWrapper = styled.div`
    align-items: start;
    background-color: ${COLOR_WHITE_WALKER};
    border-radius: ${BORDER_RADIUS};
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 30px;
    text-align: left;
    margin: 40px auto;
    max-width: 450px;
    width: 100%;

    h1 {
        margin-bottom: 0;
    }
    form {
        width: 100%;
    }

    .codeContainer {
        margin: 10px 0 50px 0;
        display: flex;
        justify-content: center;
    }

    .codeInput {
        border: 1px solid ${COLOR_GREY_FOG};
        width: 50px;
        height: 50px;
        text-align: center;
        margin: 0 5px;
        font-size: 20px;

        :focus {
            border-color: ${COLOR_DARK_GRAY};
        }
    }
`;

const initialFormData: IFormData = {
    name: '',
    password: '',
};

const Login = () => {
    const { t, i18n } = useTranslation();
    const changeUserLanguage = useChangeUserLanguageMutation();
    const currentLanguage = i18n.language;
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, any>>();

    const link =
        currentLanguage === 'fi' ? 'https://kevytyrittajat.eezy.fi/' : 'https://kevytyrittajat.eezy.fi/en/';

    const auth: IAuth = useSelector((state: IRootState) => state.auth);
    const passwordRequested: boolean | null = useSelector(
        (state: IRootState) => state.auth.passwordRequested,
    );

    const { error, loading } = auth;

    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState<any>(null);

    const isLoggedIn = useSelector((state: IRootState) => state.auth.loggedIn);

    useEffect(() => {
        if (isLoggedIn) {
            const urlParams: any = queryString.parse(location.search);
            if (urlParams.next) {
                navigate(urlParams.next);
                return;
            }

            navigate('/', { replace: true });
        }
    }, [isLoggedIn]);

    useEffect(() => {
        dispatch(hideError());
        const urlParams: any = queryString.parse(location.search);
        const routeState = location.state;

        if (routeState?.name && routeState?.password) {
            setFormData({ ...formData, name: routeState.name, password: routeState.password });
            dispatch(login({ name: routeState.name, password: routeState.password }));
            window.history.replaceState(null, '');
        }

        if (urlParams.existingEmail) {
            setFormData({ ...formData, name: urlParams.existingEmail });
            setErrors({ ...formData, auth: 'login.accountExists' });
        } else if (urlParams.email) {
            setFormData({ ...formData, name: urlParams.email });
        }

        if (urlParams.lang) {
            if (urlParams.lang === 'en') {
                changeUserLanguage('en');
            } else {
                changeUserLanguage('fi');
            }
        }
    }, []);

    const handleChange = (val: string, name: string) => {
        const newFormData = { ...formData, [name]: val };
        setFormData(newFormData);
    };

    const handleSubmit = (e?: FormEvent<HTMLButtonElement>) => {
        if (e) {
            e.preventDefault();
        }

        dispatch(login(formData));
    };

    const handleForgottenPassword = (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        saveResetEmail(formData.name);
        dispatch(requestResetPassword({ email: formData.name }));
    };

    const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    useEffect(() => {
        if (formData.tfToken?.length === VERIFICATION_CODE_LENGTH) {
            handleSubmit();
        }
    }, [formData.tfToken]);

    useEffect(() => {
        onLogoutWithholdingTax();
    }, []);

    return (
        <>
            <Bar>
                <BarContent>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ flex: 1 }}>
                            {passwordRequested && (
                                <InlineButtonLink onClick={() => window.location.reload()}>
                                    <Icon
                                        icon={['far', 'arrow-left']}
                                        style={{
                                            color: COLOR_LILA,
                                            fontWeight: 600,
                                        }}
                                    />
                                </InlineButtonLink>
                            )}
                        </div>
                        <LanguageOptions />
                    </div>
                </BarContent>
            </Bar>
            <PurpleBackground>
                <ContentWrapper>
                    <a href={link}>
                        <img src={logoFull} alt="Eezy Kevytyrittäjät" />
                    </a>
                    <LoginWrapper>
                        {passwordRequested ? (
                            <PasswordLinkSent email={formData.name} />
                        ) : (
                            <>
                                <UniqueTitleCenter>
                                    {auth.verificationCodeRequired
                                        ? t('login.enter-verification-code')
                                        : t('login.login')}
                                </UniqueTitleCenter>

                                <form>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            minHeight: '40px',
                                        }}
                                    >
                                        {loading && <LoadingSpinner />}
                                        {error && !error.place && <ErrorBox>{error.title}</ErrorBox>}
                                        {errors?.auth && <ErrorBox>{t(errors?.auth)}</ErrorBox>}
                                    </div>
                                    {auth.verificationCodeRequired ? (
                                        <>
                                            <p
                                                style={{
                                                    textAlign: 'center',
                                                    marginBottom: 40,
                                                    marginTop: 20,
                                                }}
                                            >
                                                {t('login.check-verification-code')}
                                            </p>
                                            <AuthCode
                                                length={VERIFICATION_CODE_LENGTH}
                                                allowedCharacters="numeric"
                                                inputClassName="codeInput"
                                                containerClassName="codeContainer"
                                                onChange={(val) => {
                                                    handleChange(val, 'tfToken');
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <FormSection>
                                                <FormInput
                                                    autoComplete="username"
                                                    autoFocus
                                                    error={error || undefined}
                                                    label={t('login.account') as string}
                                                    labelColor={COLOR_BLACKWATER}
                                                    name="name"
                                                    onChange={handleChange}
                                                    onKeyPress={handleEnter}
                                                    required
                                                    type="email"
                                                    value={formData.name}
                                                />
                                                <FormInput
                                                    autoComplete="current-password"
                                                    error={error || undefined}
                                                    label={t('login.password') as string}
                                                    labelColor={COLOR_BLACKWATER}
                                                    name="password"
                                                    onChange={handleChange}
                                                    onKeyPress={handleEnter}
                                                    required
                                                    type="password"
                                                    value={formData.password}
                                                />
                                            </FormSection>
                                            <FormSection
                                                style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <EezyButton
                                                    color="purple"
                                                    onClick={handleForgottenPassword}
                                                    type="button"
                                                >
                                                    {t('login.change-pwd')}
                                                </EezyButton>
                                                <EezyButton
                                                    color="purple"
                                                    hasIcon
                                                    iconAlignment="right"
                                                    dark
                                                    onClick={handleSubmit}
                                                    type="submit"
                                                    style={{
                                                        padding:
                                                            window.innerWidth < SCREEN_XS
                                                                ? '0 24px'
                                                                : '0 32px',
                                                    }}
                                                >
                                                    {t('login.login')}
                                                    <Icon color={COLOR_LILA} icon={['far', 'arrow-right']} />
                                                </EezyButton>
                                            </FormSection>
                                        </>
                                    )}

                                    {!auth.verificationCodeRequired && (
                                        <FormSection>
                                            <PLight>{t('login.login-help')}</PLight>
                                            <PLight>
                                                {t('login.not-registered')}{' '}
                                                <InlineButtonLink
                                                    onClick={() => navigate('/register')}
                                                    type="button"
                                                >
                                                    {t('login.register-here')}
                                                </InlineButtonLink>
                                            </PLight>
                                        </FormSection>
                                    )}
                                </form>
                            </>
                        )}
                    </LoginWrapper>
                </ContentWrapper>
            </PurpleBackground>
            <Footer />
        </>
    );
};

export default Login;
