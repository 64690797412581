import GET_WITHHOLDING_TAX from 'containers/profile/queries/getWithholdingTax';
import _throttle from 'lodash/throttle';
import type { StoredWithholdingTax } from '../../../../shared/src/types/user';

/**
 * Aava Withholding Tax prevents multiple requests in short period, which will throw alarm frequently. We store fetched 'ok' result during a logged in session
 */

const storageKey = '_wht';

const storeWithholdingTaxResult = (data: StoredWithholdingTax) => {
    const store = data;
    localStorage.setItem(storageKey, JSON.stringify(store));
};
const getWithholdingTaxResult = (): null | StoredWithholdingTax => {
    const str = localStorage.getItem(storageKey);
    if (!str) {
        return null;
    }

    return JSON.parse(str);
};

const fetchGetWithholdingTax = _throttle(async (userId: number): Promise<StoredWithholdingTax> => {
    const inStore = getWithholdingTaxResult();

    if (inStore && inStore.userId === userId) {
        inStore.text = 'no_change';
        return inStore;
    }

    const query = GET_WITHHOLDING_TAX.loc?.source.body;

    const res = await fetch('/v2/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            operation: 'GetWithholdingTax',
            query: query?.trim(),
        }),
    }).then((res) => res.json());

    const result: StoredWithholdingTax = { ...res.data.userWithholdingTax, userId: userId };
    if (result.status === 'ok') {
        storeWithholdingTaxResult(result);
    }

    return result;
}, 120_000);

export default fetchGetWithholdingTax;
