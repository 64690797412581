import { useEffect, useState } from 'react';
import { Box, LinearProgress, type LinearProgressProps } from '@mui/material';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import type { IInvoice, InvoiceStatusMessage, InvoiceStatus } from '../../../../shared/src/types/invoice';
import Stars from '../../assets/images/notifications/stars.svg';
import { Flex } from 'components/Flex';
import { Icon } from 'components/Icon';
import LoadingSpinner from 'components/Loading';
import { BodyP, LabelCapsSmall } from 'components/textElements';
import { COLOR_BLACKWATER, COLOR_IMPORTANT, COLOR_MEDIUM_BG, COLOR_STATUS_WAITING } from 'styles/variables';
import { errHasKey } from 'utils/error';
import { isEditable } from 'utils/invoice/invoiceLogic';
import type { formatValidationResult } from 'utils/validation';
import { useTranslation } from 'react-i18next';
import { GET_INVOICES } from '../dashboard/queries';

const LinearProgressBar = styled(LinearProgress)`
    &.MuiLinearProgress-root {
        border-radius: 10px;
        height: 10px;
        width: 97%;
    }
    &.MuiLinearProgress-colorPrimary {
        background-color: ${COLOR_MEDIUM_BG};
    }
    & .MuiLinearProgress-bar {
        width: 97%;
        background-color: ${COLOR_STATUS_WAITING};
        border-radius: 5px;
    };
`;

const StatusTitle = styled.p`
    margin-top: 22px;
    color: ${COLOR_BLACKWATER};
    font-size: 15px;
`;

const StarImg = styled.img`
    position: absolute;
    right: 10px;
    top: -10px;
`;

interface IInvoiceFillProgressStatus extends LinearProgressProps {
    invoice?: IInvoice;
    invoiceErrors: ReturnType<typeof formatValidationResult>;
    invoiceSendingStatus?: InvoiceStatusMessage;
    isNew?: boolean;
}

const calculateProgress = (
    err: ReturnType<typeof formatValidationResult>,
    invoice?: IInvoice,
    isNew?: boolean,
): number => {
    let progress = 0;
    if (invoice?.recipient && !errHasKey('recipient')(err)) progress += 23;
    if (
        invoice?.template &&
        !errHasKey('template')(err) &&
        invoice?.occupation &&
        !errHasKey('occupation')(err)
    )
        progress += 23;
    if (
        (invoice?.dueDate !== null && !errHasKey('dueDate')(err)) ||
        (invoice?.paymentTerm !== null && !errHasKey('paymentTerm')(err))
    )
        progress += 24;
    if (invoice?.invoiceItems?.length && !errHasKey('invoiceItems')(err) && !errHasKey('totalWithVat')(err))
        progress += 25;
    if (!isEditable(invoice, isNew)) progress += 5;
    return Math.min(progress, 100);
};

const TRUSTMARY_SCRIPT_SRC = 'https://widget.trustmary.com/R-EIuqlc1Y';
const AAVA_STATES: InvoiceStatus[] = [
    'invoiced',
    'paid',
    'overdued',
    'accepted',
    'unaccepted',
    'turned_back',
];

const loadTrustmaryScript = (src: string): Promise<HTMLScriptElement> => {
    return new Promise((resolve, reject) => {
        const existingScript = document.querySelector(`script[src="${src}"]`);
        if (existingScript) {
            resolve(existingScript as HTMLScriptElement);
            return;
        }
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = () => resolve(script);
        script.onerror = (err) => reject(new Error(`Script load error for ${src}: ${err}`));
        document.body.appendChild(script);
    });
};

const cleanupTrustmaryElements = () => {
    const existingScript = document.querySelector(`script[src="${TRUSTMARY_SCRIPT_SRC}"]`);
    if (existingScript) {
        existingScript.remove();
    }
    const widgetWrapper = document.querySelector('div[id^="tm-"], iframe[src*="trustmary"]');
    if (widgetWrapper) {
        widgetWrapper.remove();
    }
};

const InvoiceFillProgressStatus = (props: IInvoiceFillProgressStatus) => {
    const { t } = useTranslation();
    const { variant, invoice, isNew, invoiceErrors, invoiceSendingStatus } = props;

    const [progress, setProgress] = useState(() => calculateProgress(invoiceErrors, invoice, isNew));
    const [title, setTitle] = useState('');

    const {
        data: allInvoicesData,
        loading: loadingInvoices,
        error: errorInvoices,
    } = useQuery(GET_INVOICES, {
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        const percentage = calculateProgress(invoiceErrors, invoice, isNew);
        setProgress(percentage);
        if (percentage < 95) setTitle(t('invoice.progress.title1') || '');
        else if (percentage < 100) setTitle(t('invoice.progress.title2') || '');
        else setTitle(t('invoice.progress.title4') || '');
    }, [invoice, isNew, invoiceErrors]);

    useEffect(() => {
        const currentInvoiceId = invoice?.id;

        if (loadingInvoices || errorInvoices || progress !== 100) {
            cleanupTrustmaryElements();
            return;
        }

        if (!allInvoicesData?.allInvoices?.items) {
            console.warn('[Trustmary] Invoice data structure unexpected:', allInvoicesData);
            cleanupTrustmaryElements();
            return;
        }

        const otherInvoices = allInvoicesData.allInvoices.items.filter(
            (inv: IInvoice) => inv.id !== currentInvoiceId,
        );

        const hasPreviouslyFinalizedInvoices = otherInvoices.some((inv: IInvoice) =>
            AAVA_STATES.includes(inv.status as InvoiceStatus),
        );

        if (!hasPreviouslyFinalizedInvoices) {
            loadTrustmaryScript(TRUSTMARY_SCRIPT_SRC).catch((error) =>
                console.error(`[Trustmary] Failed to load script ${TRUSTMARY_SCRIPT_SRC}:`, error),
            );
        } else {
            cleanupTrustmaryElements();
        }
    }, [progress, allInvoicesData, loadingInvoices, errorInvoices, invoice]);

    useEffect(() => {
        return () => {
            cleanupTrustmaryElements();
        };
    }, []);

    const showPending =
        invoice?.status === 'sending_pending' ||
        (invoice?.id === invoiceSendingStatus?.originalId && invoiceSendingStatus?.status === 'pending');

    const showError =
        invoice?.sendingFailed ||
        (invoice?.id === invoiceSendingStatus?.originalId && invoiceSendingStatus?.status === 'error');

    if (!invoice && !isNew) return null;

    if (showPending) {
        return (
            <Flex style={{ margin: '20px 0', padding: '0 20px' }}>
                <LoadingSpinner size="1.5em" />
                <BodyP color={COLOR_BLACKWATER} style={{ marginLeft: 16 }}>
                    {t('invoice.sending-pending-1')} {t('invoice.sending-pending-2')}
                </BodyP>
            </Flex>
        );
    }
    if (showError) {
        return (
            <Flex style={{ margin: '20px 0' }}>
                <Icon icon={['far', 'exclamation-triangle']} color={COLOR_IMPORTANT} />
                <div style={{ marginLeft: 16 }}>
                    <BodyP color={COLOR_IMPORTANT}>{t('invoice.sending-failed-1')}</BodyP>
                    <BodyP color={COLOR_BLACKWATER}>{t('invoice.sending-failed-2')}</BodyP>
                </div>
            </Flex>
        );
    }

    return (
        <div style={{ position: 'relative' }}>
            <StatusTitle>{title}</StatusTitle>
            <Flex fullWidth center style={{ marginBottom: 20, marginTop: 5 }}>
                <LabelCapsSmall style={{ color: 'black', marginRight: 10, whiteSpace: 'nowrap' }}>
                    {t('form.percent', { percentage: progress })}
                </LabelCapsSmall>
                <Flex fullWidth>
                    <LinearProgressBar variant={variant} value={progress} />
                </Flex>
            </Flex>
            {progress === 100 && (
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    <StarImg src={Stars} />
                </Box>
            )}
        </div>
    );
};

export default InvoiceFillProgressStatus;
