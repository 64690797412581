import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { cn } from 'utils';
import { IconButton } from './IconButton';

type Icon = Parameters<typeof FontAwesomeIcon>[0]['icon'];

export type InputProps = {
    label?: string;
    startIcon?: Icon;
    endIconButton?: Icon;
    helpText?: string;
    error?: string;
    unit?: string;
    onEndIconButtonClick?: () => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            className,
            type,
            label,
            startIcon,
            endIconButton,
            onEndIconButtonClick,
            helpText,
            error,
            unit,
            ...props
        },
        ref,
    ) => {
        const uuid = React.useRef<string>(Math.random().toString(36).substring(7));
        const inputRef = React.useRef<HTMLInputElement>(null);

        // This will allow us to use forwardRef and focus properly on the input when clicking the icons etc.
        React.useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);
        return (
            <div className="!font-jakarta flex flex-col gap-1.5 leading-normal">
                {label && (
                    <label htmlFor={props.id ?? uuid.current} className="tg-caption-bold">
                        {label}
                    </label>
                )}
                <div
                    className={cn(
                        'flex items-center justify-between group h-12 sm:h-10 w-full rounded-lg border border-gray-200 active:border-gray-300 bg-background px-4 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-within:border-transparent focus-within:ring-2 focus-within:ring-violet-500 disabled:cursor-not-allowed disabled-within:opacity-50 gap-3 relative disabled-within:bg-gray-50 disabled-within:border-gray-200',
                        error && 'border-red-700',
                        className,
                    )}
                    onClick={() => inputRef.current?.focus()}
                >
                    {startIcon && <FontAwesomeIcon icon={startIcon} />}
                    <input
                        id={props.id ?? uuid.current}
                        className="grow disabled:bg-transparent truncate"
                        type={type}
                        ref={inputRef}
                        aria-label={props['aria-label'] ?? props.placeholder}
                        {...props}
                    />

                    {unit && <div className="font-semibold">{unit}</div>}

                    {endIconButton && <IconButton icon={endIconButton} onClick={onEndIconButtonClick} />}
                    {type === 'date' && (
                        // This hides the default input date picker button
                        <div
                            className="absolute size-5 bg-white top-1/2 -translate-y-1/2 right-4"
                            onClick={() => inputRef.current?.focus()}
                        />
                    )}
                </div>
                {helpText && <p className="text-xs text-gray-500">{helpText}</p>}
                {error && (
                    <p className="text-xs text-red-800">
                        <FontAwesomeIcon icon={['far', 'circle-exclamation']} className="align-middle mr-2" />
                        {error}
                    </p>
                )}
            </div>
        );
    },
);
Input.displayName = 'Input';

export { Input };
