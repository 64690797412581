import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import type { UiLanguage } from '../../../shared/src/types/user';
import {
    AUTH_ACTION_BEGIN,
    AUTH_ACTION_FAILURE,
    AUTH_FAILURE,
    AUTH_FAILURE_HIDE,
    HIDE_MODAL,
    LOGIN_SHOW,
    LOGIN_SUCCESS,
    PASSWORD_REQUESTED,
    PASSWORD_REQUESTED_FAILURE,
    RECENTLY_IDENTIFIED,
    VERIFICATION_CODE_REQUIRED,
    SHOW_MODALS,
} from 'actionTypes';
import { GtagCommands, GTMEvents } from 'constants/user';
import API from '../utils/API';
import onLoginBannerCallback from '../components/banners/EezyDataBanner/callback.onLogin';
import {
    ERROR_LOGIN_CREDENTIALS,
    ERROR_LOGIN_VERIFICATION_CODE,
    ERROR_LOGIN_OTHER,
    ERROR_LOGIN_TOO_MANY_TIMES,
    ERROR_PWD_CHANGE_OTHER,
    ERROR_REGISTER_OTHER,
    ERROR_REQUEST_PWD,
    ERROR_SERVER,
} from 'utils/error';

const loginPath = '/login';
const registerPath = '/user/register';
const resetPasswordPath = '/user/reset-password';
const requestPasswordPath = '/user/request-reset-password';
const logoutPath = '/logout';
const registerStrongAuthPath = '/auth/register';
const userStrongAuthPath = '/auth/user';

interface ILoginData {
    name: string;
    password: string;
    tfToken?: string;
}

export const login = (loginData: ILoginData) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(authActionBegin());

    try {
        const response = await API.post(loginPath, loginData);

        if (response.data.status === 'tw_token_needed') {
            dispatch(verificationCodeRequired());
            return;
        }

        localStorage.setItem('login-time', new Date().toISOString());
        dispatch(loginSuccess());
    } catch (err) {
        if (err?.response?.status === 401) {
            sessionStorage.removeItem('jwt');
            if (loginData.tfToken) {
                dispatch(authActionFailure(ERROR_LOGIN_VERIFICATION_CODE));
            } else {
                dispatch(authActionFailure(ERROR_LOGIN_CREDENTIALS));
            }
        } else if (err?.response?.status === 429) {
            dispatch(authActionFailure(ERROR_LOGIN_TOO_MANY_TIMES));
        } else if (err?.response?.status === 503) {
            dispatch(serverWholePageError());
        } else {
            dispatch(authActionFailure(ERROR_LOGIN_OTHER));
        }
    }

    onLoginBannerCallback();
};

export interface IRegisterData {
    bonusCode: string;
    email: string;
    firstName: string;
    language: UiLanguage;
    lastName: string;
    phone: string;
    phoneCountryCode: number;
    referralInfo: string;
    referralOther: string;
}

export const register = (data: IRegisterData) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(authActionBegin());

    await API.post(registerPath, data)
        .then(() => {
            window.gtag?.(GtagCommands.event, GTMEvents.sign_up);
            dispatch({ type: PASSWORD_REQUESTED });
        })
        .catch((err) => {
            const error = err?.response?.data?.errors ? err.response.data?.errors[0] : null;
            if (error && error === 'ALREADY_AUTHENTICATED') {
                dispatch(authActionFailure(error));
                // show the note for user for 2 secs before logging in
                setTimeout(() => {
                    dispatch(loginSuccess());
                }, 2000);
            } else if (error) {
                dispatch(authActionFailure(error));
            } else {
                dispatch(authActionFailure(ERROR_REGISTER_OTHER));
            }
        });
};

export interface IStrongAuthData {
    action: string;
    lang: string;
}

export const strongAuth = (data: IStrongAuthData) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(authActionBegin());
    const strongAuthPath = `${
        data.action === 'signup' ? registerStrongAuthPath : userStrongAuthPath
    }?action=${data.action}&lang=${data.lang}`;

    await API.get(strongAuthPath)
        .then((response) => {
            const resdata = response.data;
            if (response.status === 200 && resdata.status === 'do_redirect') {
                window.location = resdata.location;
            } else {
                dispatch(authActionFailure(ERROR_REGISTER_OTHER));
            }
        })
        .catch(() => {
            dispatch(authActionFailure(ERROR_REGISTER_OTHER));
        });
};

export interface IResetPasswordData {
    email: string;
    password: string;
    password2: string;
    token: string;
}

export const resetPassword =
    (data: IResetPasswordData) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(authActionBegin());

        await API.post(resetPasswordPath, data)
            .then(() => {
                window.gtag?.(GtagCommands.event, GTMEvents.reset_password);
                dispatch(login({ name: data.email, password: data.password }));
            })
            .catch((err) => {
                const error = err?.response?.data?.errors ? err.response.data?.errors[0] : null;
                dispatch({ type: PASSWORD_REQUESTED_FAILURE });
                if (error) {
                    dispatch(authActionFailure(error));
                } else {
                    dispatch(authActionFailure(ERROR_PWD_CHANGE_OTHER));
                }
            });
    };

interface IRequestPasswordData {
    email: string;
}

export const requestResetPassword =
    (data: IRequestPasswordData) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(authActionBegin());

        await API.post(requestPasswordPath, data)
            .then(() => {
                dispatch({ type: PASSWORD_REQUESTED });
            })
            .catch(() => {
                dispatch(authActionFailure(ERROR_REQUEST_PWD));
            });
    };

export const logout = () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    await API.post(logoutPath)
        .then(() => {
            dispatch(showLogin());
            sessionStorage.removeItem('jwt');
            localStorage.setItem('logout-event', `logout${Math.random()}`);
            // reload to close the websocket for subscriptions
            window.location.reload();
        })
        .catch(() => {
            dispatch(showLogin());
            window.location.reload();
        });
};

export const hideError = () => ({
    type: AUTH_FAILURE_HIDE,
});

const authActionBegin = () => ({
    type: AUTH_ACTION_BEGIN,
});

export const authActionFailure = (errorType: string) => ({
    payload: { errorType },
    type: AUTH_ACTION_FAILURE,
});

export const loginSuccess = () => ({
    type: LOGIN_SUCCESS,
});

const verificationCodeRequired = () => ({
    type: VERIFICATION_CODE_REQUIRED,
});

export const recentlyIdentified = () => {
    window.gtag?.(GtagCommands.event, GTMEvents.identity_verified);
    return {
        type: RECENTLY_IDENTIFIED,
    };
};

export const showLogin = () => ({
    type: LOGIN_SHOW,
});

export const serverWholePageError = (message?: string) => ({
    payload: {
        data: {
            message,
        },
        errorType: ERROR_SERVER,
    },
    type: AUTH_FAILURE,
});

export const customError = (errorType: string) => ({
    payload: { errorType },
    type: AUTH_FAILURE,
});

export const customErrorWithData = ({ errorType, data }: { errorType: string; data?: any }) => ({
    payload: { errorType, data },
    type: AUTH_FAILURE,
});

export const showModals = (modals: string[]) => ({
    payload: modals,
    type: SHOW_MODALS,
});

export const hideModal = (modal: string) => ({
    payload: modal,
    type: HIDE_MODAL,
});
